import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import Helmet from 'react-helmet';
import 'aos/dist/aos.css';

import SEO from '../../components/seo';
import Footer from '../../sections/Footer';
import Wrap from '../../components/Wrap';

const NotFoundPage = () => {
  const [aosInit, setAosInit] = useState(false);

  useEffect(() => {
    if (!aosInit) {
      AOS.init({
        duration: 600,
        easing: 'ease-in-sine',
      });
      setAosInit(true);
    } else {
      AOS.refreshHard();
    }
  });
  return (
    <>
      <SEO title="General terms" />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Wrap>
        <h2>Firstversionist</h2>
        <h1>Algemene voorwaarden</h1>
        <p>
          <em>General terms and conditions</em>
        </p>
        <h3 id="artikel-1-definities">Artikel 1 – Definities</h3>
        <ol>
          <li>
            Firstversionist B.V., gevestigd te Zoetermeer, KvK–nummer 74263404, wordt in deze algemene voorwaarden
            aangeduid als <strong>dienstverlener</strong>.
          </li>
          <li>
            De wederpartij van dienstverlener wordt in deze algemene voorwaarden aangeduid als{' '}
            <strong>opdrachtgever</strong>.
          </li>
          <li>
            <strong>Partijen</strong> zijn dienstverlener en opdrachtgever samen. Ieder individueel zijn ze een{' '}
            <strong>partij</strong>.
          </li>
          <li>
            Met de <strong>overeenkomst</strong> wordt bedoeld de overeenkomst tot dienstverlening tussen partijen.
          </li>
          <li>
            Onder “<strong>schriftelijk</strong>” wordt in deze algemene voorwaarden ook per e–mail verstaan.
          </li>
        </ol>
        <h3 id="artikel-2-toepasselijkheid-algemene-voorwaarden">Artikel 2 – Toepasselijkheid algemene voorwaarden</h3>
        <ol>
          <li>
            Deze voorwaarden zijn van toepassing op alle offertes, aanbiedingen, werkzaamheden, overeenkomsten en
            leveringen van diensten of goederen door of namens dienstverlener.
          </li>
          <li>
            Afwijken van deze voorwaarden kan alleen als dat uitdrukkelijk én schriftelijk door partijen is
            overeengekomen.
          </li>
          <li>
            De overeenkomst bevat voor dienstverlener steeds inspanningsverplichtingen, geen resultaatsverplichtingen.
          </li>
        </ol>
        <h3 id="artikel-3-betaling">Artikel 3 – Betaling</h3>
        <ol>
          <li>
            Declaraties moeten binnen 14 dagen na factuurdatum betaald zijn, tenzij partijen hierover schriftelijk
            andere afspraken hebben gemaakt of op de declaratie een andere betaaltermijn vermeld is.
          </li>
          <li>
            Betalingen geschieden zonder enig beroep op opschorting of verrekening door overschrijving van het
            verschuldigde bedrag op het door dienstverlener opgegeven bankrekeningnummer.
          </li>
          <li>
            Betaalt opdrachtgever niet binnen de overeengekomen termijn, dan is hij van rechtswege, zonder dat daarvoor
            enige aanmaning nodig is, in verzuim. Vanaf dat moment is dienstverlener gerechtigd de verplichtingen op te
            schorten totdat opdrachtgever aan zijn betalingsverplichtingen heeft voldaan.
          </li>
          <li>
            Blijft opdrachtgever in gebreke, dan zal dienstverlener tot invordering overgaan. De kosten met betrekking
            tot die invordering komen voor rekening van de opdrachtgever. Wanneer opdrachtgever in verzuim is, is hij
            naast de hoofdsom tevens wettelijke (handels)rente, buitengerechtelijke incassokosten en overige schade
            verschuldigd aan dienstverlener. De incassokosten worden berekend aan de hand van het Besluit vergoeding
            voor buitengerechtelijke incassokosten.
          </li>
          <li>
            In geval van liquidatie, faillissement, beslag of surseance van betaling van de opdrachtgever zijn de
            vorderingen van dienstverlener op de opdrachtgever onmiddellijk opeisbaar.
          </li>
          <li>
            Weigert opdrachtgever zijn medewerking aan de uitvoering van de opdracht door dienstverlener, dan is hij nog
            steeds verplicht de afgesproken prijs aan dienstverlener te betalen.
          </li>
        </ol>
        <h3 id="artikel-4-aanbiedingen-en-offertes">Artikel 4 – Aanbiedingen en offertes</h3>
        <ol>
          <li>
            De aanbiedingen van dienstverlener zijn hooguit 1 maand geldig, tenzij in het aanbod een andere termijn van
            aanvaarding is genoemd. Wordt het aanbod niet binnen die gestelde termijn aanvaard, dan vervalt het aanbod.
          </li>
          <li>
            Levertijden in offertes zijn indicatief en geven de opdrachtgever bij overschrijding daarvan geen recht op
            ontbinding of schadevergoeding, tenzij partijen uitdrukkelijk én schriftelijk anders zijn overeengekomen.
          </li>
          <li>
            Aanbiedingen en offertes gelden niet automatisch voor nabestellingen. Partijen moeten dit uitdrukkelijk én
            schriftelijk overeenkomen.
          </li>
        </ol>
        <h3 id="artikel-5-prijzen">Artikel 5 – Prijzen</h3>
        <ol>
          <li>
            De op aanbiedingen, offertes en facturen van dienstverlener genoemde prijzen zijn exclusief btw en eventuele
            andere overheidsheffingen, tenzij uitdrukkelijk anders vermeld.
          </li>
          <li>
            De prijzen van goederen zijn gebaseerd op de op dat moment bekend zijnde kostprijzen. Verhogingen hiervan,
            die door de dienstverlener niet konden worden voorzien ten tijde van het doen van de aanbieding c.q. het tot
            stand komen van de overeenkomst, kunnen aanleiding geven tot prijsverhogingen.
          </li>
          <li>
            Ten aanzien van de dienstverlening kunnen partijen bij de totstandkoming van de overeenkomst een vaste prijs
            overeenkomen.
          </li>
          <li>
            Indien er geen vaste prijs is overeengekomen, kan het tarief met betrekking tot de dienstverlening worden
            vastgesteld op grond van de werkelijk bestede uren. Het tarief wordt berekend volgens de gebruikelijke
            uurtarieven van dienstverlener, geldend voor de periode waarin hij de werkzaamheden verricht, tenzij een
            daarvan afwijkend uurtarief is overeengekomen.
          </li>
          <li>
            Indien geen tarief op grond van de werkelijk bestede uren is afgesproken, wordt voor de dienstverlening een
            richtprijs afgesproken, waarbij dienstverlener gerechtigd is om hier tot 10% vanaf te afwijken. Indien de
            richtprijs meer dan 10% hoger uit gaat vallen, dient dienstverlener opdrachtgever tijdig te laten weten
            waarom een hogere prijs gerechtvaardigd is. Opdrachtgever heeft in dat geval het recht om een deel van de
            opdracht te laten vervallen, dat boven de richtprijs vermeerderd met 10% uitkomt.
          </li>
        </ol>
        <h3 id="artikel-6-prijsindexering">Artikel 6 – Prijsindexering</h3>
        <ol>
          <li>
            De bij het aangaan van de overeenkomst overeengekomen prijzen en uurlonen zijn gebaseerd op het op dat
            moment gehanteerde prijspeil. Dienstverlener heeft het recht de aan opdrachtgever te berekenen vergoedingen
            jaarlijks per 1 januari aan te passen.
          </li>
          <li>Aangepaste prijzen, tarieven en uurlonen worden zo spoedig mogelijk medegedeeld aan opdrachtgever.</li>
        </ol>
        <h3 id="artikel-7-informatieverstrekking-door-opdrachtgever">
          Artikel 7 – Informatieverstrekking door opdrachtgever
        </h3>
        <ol>
          <li>
            Opdrachtgever stelt alle informatie die voor de uitvoering van de opdracht relevant is beschikbaar voor
            dienstverlener.
          </li>
          <li>
            Opdrachtgever is verplicht alle gegevens en bescheiden, die dienstverlener meent nodig te hebben voor het
            correct uitvoeren van de opdracht, tijdig en in de gewenste vorm en op de gewenste wijze ter beschikking te
            stellen.
          </li>
          <li>
            Opdrachtgever staat in voor de juistheid, volledigheid en betrouwbaarheid van de aan dienstverlener ter
            beschikking gestelde gegevens en bescheiden, ook indien deze van derden afkomstig zijn, voor zover uit de
            aard van de opdracht niet anders voortvloeit.
          </li>
          <li>
            Opdrachtgever vrijwaart dienstverlener voor elke schade in welke vorm dan ook die voortvloeit uit het niet
            voldoen aan het in het eerste lid van dit artikel gestelde.
          </li>
          <li>
            Indien en voor zover opdrachtgever dit verzoekt, retourneert dienstverlener de betreffende bescheiden.
          </li>
          <li>
            Stelt opdrachtgever niet, niet tijdig of niet behoorlijk de door dienstverlener verlangde gegevens en
            bescheiden beschikbaar en loopt de uitvoering van de opdracht hierdoor vertraging op, dan komen de daaruit
            voortvloeiende extra kosten en extra honoraria voor rekening van opdrachtgever.
          </li>
        </ol>
        <h3 id="artikel-8-intrekking-opdracht">Artikel 8 – Intrekking opdracht</h3>
        <ol>
          <li>
            Tenzij anders overeengekomen is het partijen niet toegestaan de overeenkomst eenzijdig voortijdig te
            ontbinden.
          </li>
          <li>
            In geval van liquidatie, faillissement, beslag of surseance van betaling van een van de partijen is de
            andere partij toegestaan om de opdracht in te trekken of te beeindigen, tenzij anders overeengekomen is.Dit
            geldt ook in het geval dat de partij ophoudt te bestaan of opgeheven wordt voor een ander doel dan een
            herstructurering of fusie, of wanneer het meerderheidsbelang in de partij veranderd.
          </li>
          <li>
            De overeenkomst mag enkel ontbonden worden op basis van een toerekenbare tekortkoming door middel van een zo
            gedetailleerd mogelijke schriftelijke ingebrekestelling, waarbij een redelijke termijn wordt gesteld
            waarbinnen de tekortkoming kan worden verholpen, tenzij deze algemene voorwaarden of de wet anders bepalen.
          </li>
          <li>
            Indien de overeenkomst wordt ontbonden, wordt alles wat door dienstverlener is geleverd en / of uitgevoerd
            alsmede de daaraan verbonden betalingsverplichting niet ongedaan gemaakt, tenzij de opdrachtgever aantoont
            dat dienstverlener in verzuim is met betrekking tot het wezenlijke deel van die diensten. Bedragen
            gefactureerd door doemstverlener vóór ontbinding in verband met hetgeen dienstverlener reeds bij de
            uitvoering van de overeenkomst behoorlijk heeft uitgevoerd of geleverd, blijven volledig met inachtneming
            van het in de vorige zin bepaalde en zijn onmiddellijk opeisbaar en betaalbaar.
          </li>
          <li>
            Als de overeenkomst wordt ontbonden, verliezen alle aan de opdrachtgever verleende rechten zijn geldigheid.
            De opdrachtgever zal niet langer bevoegd zijn om gebruik te maken van hetgeen waarop deze overeenkomst
            betrekking heeft.
          </li>
          <li>
            Wanneer opdrachtgever de opdracht intrekt, is opdrachtgever verplicht het verschuldigde loon en de gemaakte
            onkosten van dienstverlener te betalen.
            <br />
          </li>
          <li>
            Artikelen die naar hun aard bedoeld zijn om na het einde van de overeenkomst van toepassing te blijven,
            blijven volledig van kracht nadat de overeenkomst is beëindigd.
          </li>
        </ol>
        <h3 id="artikel-9-uitvoering-van-de-overeenkomst">Artikel 9 – Uitvoering van de overeenkomst</h3>
        <ol>
          <li>
            Dienstverlener voert de overeenkomst naar beste inzicht en vermogen en overeenkomstig de eisen van goed
            vakmanschap uit.
          </li>
          <li>Dienstverlener heeft het recht om werkzaamheden te laten verrichten door derden.</li>
          <li>
            De uitvoering geschiedt in onderling overleg en na schriftelijk akkoord en betaling van het eventueel
            afgesproken voorschot.
          </li>
          <li>
            Het is de verantwoordelijkheid van opdrachtgever dat dienstverlener tijdig kan beginnen aan de opdracht.
          </li>
          <li>
            Wanneer partijen overeengekomen zijn de overeenkomst in fases op te leveren, mag dienstverlener
            werkzaamheden aan een fase opschorten zolang de voorgaande fase niet in schrift is goedgekeurd door
            opdrachtgever.
          </li>
          <li>
            Dienstverlener is niet verplicht instructies te volgen die buiten de originele opdracht vallen, of de
            originele opdracht substantieel aanpassen. Wanneer dergelijke instructies opgevolgd worden zal
            dienstverlener hier opdrachtgever van op de hoogte stellen, en de gemaakte werkzaamheden doorberekenen
            volgens het gebruikelijke uurtarief van dienstverlener.
          </li>
          <li>
            De werkzaamheden of het opgeleverde worden door Partijen geaccepteerd wanneer er niet binnen 5 werkdagen in
            schrift door opdrachtgever onderbouwd volledig wordt aangegeven deze niet te accepteren. In het geval dat de
            werkzaamheden of het opgeleverde niet wordt geaccepteerd, zal dienstverlener deze aanpassen of vervangen
            inzover dit in lijn is met de overeenkomst. Hierop herhaalt de genoemde procedure.
          </li>
        </ol>
        <h3 id="artikel-10-contractduur-opdracht">Artikel 10 – Contractduur opdracht</h3>
        <ol>
          <li>
            De overeenkomst tussen opdrachtgever en dienstverlener wordt aangegaan voor onbepaalde tijd, tenzij uit de
            aard van de overeenkomst iets anders voortvloeit of partijen uitdrukkelijk én schriftelijk anders zijn
            overeengekomen.
          </li>
          <li>
            Zijn partijen binnen de looptijd van de overeenkomst voor de voltooiing van bepaalde werkzaamheden een
            termijn overeengekomen, dan is dit nooit een fatale termijn. Bij dreigende overschrijding van een termijn
            zullen dienstverlener en opdrachtgever met elkaar overleggen om de gevolgen van een overschrijding te
            bespreken.
          </li>
          <li>
            Er is slechts sprake van verzuim bij overschrijding van een termijn wanneer opdrachtgever dienstverlener
            schriftelijk in gebreke stelt. De ingebrekestelling dient een zo volledig en gedetailleerd mogelijke
            omschrijving van de tekortkomingen te bevatten, zodat dienstverlener hier adequaat op kan reageren.
          </li>
          <li>
            Dienstverlener sluit uitdrukkelijk elk recht op financiële compensatie uit als gevolg van te late
            oplevering.
          </li>
        </ol>
        <h3 id="artikel-11-wijziging-van-de-overeenkomst">Artikel 11 – Wijziging van de overeenkomst</h3>
        <ol>
          <li>
            Indien tijdens de uitvoering van de overeenkomst blijkt dat het voor een behoorlijke uitvoering van de
            opdracht noodzakelijk is om de te verrichten werkzaamheden te wijzigen of aan te vullen, passen partijen
            tijdig en in onderling overleg de overeenkomst dienovereenkomstig aan.
          </li>
          <li>
            Indien partijen overeenkomen dat de overeenkomst wordt gewijzigd of aangevuld, kan het tijdstip van
            voltooiing van de uitvoering daardoor worden beïnvloed. Dienstverlener stelt opdrachtgever hiervan zo
            spoedig mogelijk op de hoogte.
          </li>
          <li>
            Indien de wijziging van of aanvulling op de overeenkomst financiële en/of kwalitatieve consequenties heeft,
            licht dienstverlener opdrachtgever hierover zo spoedig mogelijk schriftelijk in.
          </li>
          <li>
            Indien partijen een vast honorarium zijn overeengekomen, geeft dienstverlener daarbij aan in hoeverre de
            wijziging of aanvulling van de overeenkomst een overschrijding van dit honorarium tot gevolg heeft.
          </li>
        </ol>
        <h3 id="artikel-12-overmacht">Artikel 12 – Overmacht</h3>
        <ol>
          <li>
            In aanvulling op het bepaalde in artikel 6:75 Burgerlijk Wetboek geldt dat een tekortkoming van
            dienstverlener in de nakoming van enige verplichting jegens opdrachtgever niet aan dienstverlener kan worden
            toegerekend in geval van een van de wil van dienstverlener onafhankelijke omstandigheid, waardoor de
            nakoming van zijn verplichtingen jegens opdrachtgever geheel of gedeeltelijk wordt verhinderd of waardoor de
            nakoming van zijn verplichtingen in redelijkheid niet van dienstverlener kan worden verlangd. Tot die
            omstandigheden worden mede gerekend wanprestaties van toeleveranciers of andere derden, stroomstoringen,
            computervirussen, stakingen, slechte weersomstandigheden en werkonderbrekingen.
          </li>
          <li>
            Indien zich een situatie als hiervoor bedoeld voordoet als gevolg waarvan dienstverlener niet aan zijn
            verplichtingen jegens opdrachtgever kan voldoen, dan worden die verplichtingen opgeschort zolang
            dienstverlener niet aan zijn verplichtingen kan voldoen. Indien de in de vorige zin bedoelde situatie 30
            kalenderdagen heeft geduurd, hebben partijen het recht de overeenkomst schriftelijk geheel of gedeeltelijk
            te ontbinden.
          </li>
          <li>
            Dienstverlener is in het geval als bedoeld in het tweede lid van dit artikel niet gehouden tot vergoeding
            van enige schade, ook niet als dienstverlener als gevolg van de overmachtstoestand enig voordeel geniet.
          </li>
        </ol>
        <h3 id="artikel-13-verrekening">Artikel 13 – Verrekening</h3>
        <p>
          Opdrachtgever doet afstand van zijn recht om een schuld aan dienstverlener te verrekenen met een vordering op
          dienstverlener.
        </p>
        <h3 id="artikel-14-opschorting">Artikel 14 – Opschorting</h3>
        <p>
          Opdrachtgever doet afstand van het recht om de nakoming van enige uit deze overeenkomst voortvloeiende
          verbintenis op te schorten.
        </p>
        <h3 id="artikel-15-overdracht-van-rechten">Artikel 15 – Overdracht van rechten</h3>
        <p>
          Rechten van een partij uit deze overeenkomst kunnen niet worden overgedragen zonder de voorafgaande
          schriftelijke instemming van de andere partij. Deze bepaling geldt als een beding met goederenrechtelijke
          werking zoals bedoeld in artikel 3:83, tweede lid, Burgerlijk Wetboek.
        </p>
        <h3 id="artikel-16-verval-van-de-vordering">Artikel 16 – Verval van de vordering</h3>
        <p>
          Elk recht op vergoeding van schade veroorzaakt door dienstverlener vervalt in elk geval 12 maanden na de
          gebeurtenis waaruit de aansprakelijkheid direct of indirect voortvloeit. Hiermee wordt niet uitgesloten het
          bepaalde in artikel 6:89Burgerlijk Wetboek.
        </p>
        <h3 id="artikel-17-verzekering">Artikel 17 – Verzekering</h3>
        <ol>
          <li>
            Opdrachtgever verplicht zich geleverde zaken die noodzakelijk zijn voor de uitvoering van de onderliggende
            overeenkomst, alsook zaken van dienstverlener die bij opdrachtgever aanwezig zijn en zaken die onder
            eigendomsvoorbehoud zijn geleverd, adequaat te verzekeren en verzekerd te houden tegen onder andere brand,
            ontploffings– en waterschade evenals diefstal.
          </li>
          <li>Opdrachtgever geeft op eerste verzoek de polis van deze verzekeringen ter inzage.</li>
        </ol>
        <h3 id="artikel-18-aansprakelijkheid-schade">Artikel 18 – Aansprakelijkheid schade</h3>
        <ol>
          <li>
            Dienstverlener is niet aansprakelijk voor schade die voortvloeit uit deze overeenkomst, tenzij
            dienstverlener de schade opzettelijk of met grove schuld heeft veroorzaakt.
          </li>
          <li>
            In het geval dat dienstverlener een schadevergoeding verschuldigd is aan opdrachtgever bedraagt de schade
            niet meer dan het honorarium, of in het geval van doorlopende contracten, niet meer dan het honorarium voor
            de duur van één jaar van het betreffende contract.
          </li>
          <li>
            Iedere aansprakelijkheid voor schade, voortvloeiende uit of verband houdende met de uitvoering van een
            overeenkomst, is steeds beperkt tot het bedrag dat in het desbetreffende geval door de gesloten
            (beroeps)aansprakelijkheidsverzekering(en) wordt uitbetaald. Dit bedrag wordt vermeerderd met het bedrag van
            het eigen risico volgens de desbetreffende polis.
          </li>
          <li>
            Dienstverlener is nimmer aansprakelijk voor indirecte schade, gevolgschade, gederfde winst, gemiste
            besparingen, verminderde goodwill, schade als gevolg van bedrijfsstagnatie, schade verband houdende met het
            gebruik van door de opdrachtgever aan dienstverlener voorgeschreven zaken, materialen of programmatuur of
            schade door opdrachtgever voorgeschreven toeleveranciers. Eveneens is uitgesloten de aansprakelijkheid van
            dienstverlener wegens verlies, vernietiging of beschadiging van gegevens of documenten.
          </li>
          <li>
            De aansprakelijkheidsbeperking geldt ook als dienstverlener aansprakelijk wordt gesteld voor schade die
            direct of indirect voortvloeit uit het niet deugdelijk functioneren van de door dienstverlener bij de
            uitvoering van de opdracht gebruikte apparatuur, software, gegevensbestanden, registers of andere zaken.
          </li>
          <li>
            Niet uitgesloten is de aansprakelijkheid van dienstverlener voor schade die het gevolg is van opzet of
            bewuste roekeloosheid van dienstverlener.
          </li>
          <li>
            In alle gevallen is dienstverlener enkel aansprakelijk als gevolg van een toerekenbare tekortkoming in de
            uitvoering van de overeenkomst indien opdrachtgever direct schriftelijk dienstverlener informeert van
            verzuim, onder vermelding van een redelijke termijn waarbinnen dienstverlener de toerekenbare tekortkomingen
            kan verhelpen, en dienstverlener na deze termijn toerekenbaar blijft tekortkomen in de nakoming van
            verplichtingen, behalve in geval van permanente toerekenbare tekortkoming. De ingebrekestelling dient een
            beschrijving van de tekortkoming te bevatten die zo volledig en gedetailleerd mogelijk is zodat
            dienstverlener in staat is adequaat te reageren.
          </li>
          <li>
            Enig recht op schadevergoeding is altijd voorwaardelijk aan het direct schriftelijk door de klant in gebreke
            stellen van de dienstverlener. Iedere vordering tot schadevergoeding tegen dienstverlener verloopt
            automatisch na twaalf (12) maanden na ontstaan van de claim.
          </li>
          <li>
            Opdrachtgever draagt het volledige risico en de verantwoordelijkheid voor het gebruik van hetgeen waarop
            deze overeenkomst betrekking heeft. Dienstverlener aanvaardt geen enkele aansprakelijkheid voor het gebruik
            dat de opdrachtgever van hetgeen waarop deze overeenkomst betrekking heeft maakt. Opdrachtgever vrijwaart
            dienstverlener voor alle aanspraken van derden die voortvloeien uit het gebruik van hetgeen waarop deze
            overeenkomst betrekking heeft door de opdrachtgever.
          </li>
        </ol>
        <h3 id="artikel-19-aansprakelijkheid-opdrachtgever">Artikel 19 – Aansprakelijkheid opdrachtgever</h3>
        <ol>
          <li>
            Ingeval een opdracht wordt verstrekt door meer dan één persoon, is ieder van hen hoofdelijk aansprakelijk
            voor de bedragen die uit hoofde van die opdracht aan dienstverlener verschuldigd zijn.
          </li>
          <li>
            Wordt een opdracht middellijk of onmiddellijk verstrekt door een natuurlijke persoon namens een
            rechtspersoon, dan kan deze natuurlijke persoon tevens in privé opdrachtgever zijn. Daarvoor is vereist dat
            deze natuurlijke persoon beschouwd kan worden als de (mede)beleidsbepaler van de rechtspersoon. Bij
            wanbetaling door de rechtspersoon is de natuurlijke persoon dus persoonlijk aansprakelijk voor de betaling
            van de declaratie, onverschillig of deze, al dan niet op verzoek van opdrachtgever, ten name van een
            rechtspersoon dan wel ten name van opdrachtgever als natuurlijk persoon of hen beiden is gesteld.
          </li>
        </ol>
        <h3 id="artikel-20-vrijwaring">Artikel 20 – Vrijwaring</h3>
        <p>
          De opdrachtgever vrijwaart dienstverlener tegen alle aanspraken van derden, die verband houden met de door
          dienstverlener geleverde goederen en/of diensten.
        </p>
        <h3 id="artikel-21-klachtplicht">Artikel 21 – Klachtplicht</h3>
        <ol>
          <li>
            Opdrachtgever is verplicht klachten over de verrichte werkzaamheden direct schriftelijk te melden aan
            dienstverlener. De klacht bevat een zo gedetailleerd mogelijke omschrijving van de tekortkoming, zodat
            dienstverlener in staat is hierop adequaat te reageren.
          </li>
          <li>
            Een klacht kan er in ieder geval niet toe leiden, dat dienstverlener gehouden kan worden om andere
            werkzaamheden te verrichten dan zijn overeengekomen.
          </li>
        </ol>
        <h3 id="artikel-22-eigendomsvoorbehoud-opschortingsrecht-en-retentierecht">
          Artikel 22 – Eigendomsvoorbehoud, opschortingsrecht en retentierecht
        </h3>
        <ol>
          <li>
            De bij opdrachtgever aanwezige zaken en geleverde zaken en onderdelen blijven eigendom van dienstverlener
            totdat opdrachtgever de gehele afgesproken prijs heeft betaald. Tot die tijd kan dienstverlener zich
            beroepen op zijn eigendomsvoorbehoud en de zaken terugnemen.
          </li>
          <li>
            Indien de overeengekomen vooruit te betalen bedragen niet of niet op tijd worden voldaan, heeft
            dienstverlener het recht om de werkzaamheden op te schorten totdat het overeengekomen deel alsnog is
            voldaan. Er is dan sprake van schuldeisersverzuim. Een verlate levering kan in dat geval niet aan
            dienstverlener worden tegengeworpen.
          </li>
          <li>
            Dienstverlener is niet bevoegd de onder zijn eigendomsvoorbehoud vallende zaken te verpanden noch op enige
            andere wijze te bezwaren.
          </li>
          <li>
            Indien zaken nog niet zijn geleverd, maar de overeengekomen vooruitbetaling of prijs niet conform afspraak
            is voldaan, heeft dienstverlener het recht van retentie. De zaak wordt dan niet geleverd totdat
            opdrachtgever volledig en conform afspraak heeft betaald.
          </li>
          <li>
            In geval van liquidatie, insolventie of surseance van betaling van opdrachtgever zijn de verplichtingen van
            opdrachtgever onmiddellijk opeisbaar.
          </li>
        </ol>
        <h3 id="artikel-23-intellectueel-eigendom">Artikel 23 – Intellectueel eigendom</h3>
        <ol>
          <li>
            Tenzij partijen schriftelijk anders zijn overeengekomen, behoudt dienstverlener alle intellectuele absolute
            rechten (waaronder auteursrecht, octrooirecht, merkenrecht, tekeningen– en modellenrecht, etc.) op alle
            rapporten, adviezen, ontwerpen, tekeningen, websites, software, logo’s, huisstijlen, geschriften, dragers
            met gegevens of andere informatie, offertes, afbeeldingen, schetsen, etc.
          </li>
          <li>
            Niets in deze algemene voorwaarden impliceerd een overdracht van het intellectueel eigendom. Opdrachtgever
            ontvangt enkel een niet–exclusieve en niet–overdraagbare gebruikslicentie op hetgeen waarop deze
            overeenkomst betrekking heeft, met de voorwaarden zoals beschreven in de overeenkomst.
          </li>
          <li>
            De genoemde intellectuele absolute rechten mogen niet zonder schriftelijke toestemming van dienstverlener
            worden gekopieerd, aan derden getoond en/of ter beschikking gesteld of op andere wijze worden gebruikt.
          </li>
          <li>
            Opdrachtgever verplicht zich tot geheimhouding van de door dienstverlener aan hem ter beschikking gestelde
            vertrouwelijke informatie. Onder vertrouwelijke informatie wordt in ieder geval verstaan datgene waarop dit
            artikel betrekking heeft, alsmede de bedrijfsgegevens. Opdrachtgever verplicht zich zijn personeel en/of
            derden die betrokken zijn bij de uitvoering van deze overeenkomst, een schriftelijke geheimhoudingsplicht op
            te leggen van de strekking van deze bepaling.
          </li>
          <li>
            Dienstverlener behoudt het recht om de tijdens de overeenkomst ontwikkelde kennis, materialen en/of
            programmatuur voor andere doeleinden te gebruiken, voorzover hierbij geen vertrouwelijke informatie aan
            derden wordt verstrekt, tenzij anders overeengekomen
          </li>
        </ol>
        <h3 id="artikel-24-privacy">Artikel 24 – Privacy</h3>
        <ol>
          <li>
            Indien dienstverlener in het kader van het uitvoeren van de opdracht persoonsgegevens van klanten van de
            opdrachtgever moet verwerken, dient dienstverlener te worden beschouwd als de bewerker (“processor”) in de
            zin van de wet bescherming persoonsgegevens en de opdrachtgever moet worden beschouwd als de
            verantwoordelijke (“controller.”)
          </li>
          <li>
            De opdrachtgever garandeert aan dienstverlener dat de gegevens niet onwettig zijn en dat zij geen inbreuk
            maken op de rechten van derden. De opdrachtgever vrijwaart dienstverlener tegen alle rechtsvorderingen of
            aanspraken van derden, waaronder toezichthouders en betrokkenen, op welke basis dan ook, in verband met de
            verwerking van deze gegevens in het kader van de overeenkomst.
          </li>
          <li>
            Op grond van de wetgeving inzake de verwerking van persoonsgegevens (zoals de Wet bescherming
            persoonsgegevens) heeft de opdrachtgever verplichtingen aan derden, zoals de verplichting om informatie vrij
            te geven, en kunnen betrokkenen hun persoonlijke gegevens inspecteren, corrigeren en verwijderen. De
            verantwoordelijkheid voor het nakomen van deze verplichtingen ligt volledig bij de opdrachtgever. Voor zover
            technisch mogelijk zal dienstverlener meewerken aan de verplichtingen die door de klant moeten worden
            nagekomen, inclusief het verzenden van verzoeken van derden in het kader van de verplichtingen van de
            opdrachtgever. De kosten die een dergelijke medewerking met zich meebrengt, komen volledig voor rekening van
            de opdrachtgever.
          </li>
          <li>
            Dienstverlener zal passende technische en organisatorische maatregelen treffen of laten uitvoeren door
            derden en deze onderhouden en indien nodig aanpassen om de Persoonsgegevens die dienstverlener verwerkt in
            opdracht van de opdrachtgever te beschermen tegen onrechtmatige verwerking. Dienstverlener verwerkt de
            persoonsgegevens verkregen van de opdrachtgever niet voor eigen doeleinden.
          </li>
          <li>
            Dienstverlener zal met de opdrachtgever de passende technische en organisatorische maatregelen bespreken die
            moeten worden genomen voor de bescherming van Persoonsgegevens tegen verlies of tegen enige andere vorm van
            onrechtmatige verwerking.
          </li>
          <li>
            Opdrachtgever erkent volledige kennis te hebben van de technische en organisatorische maatregelen die moeten
            worden uitgevoerd door dienstverlener. Opdrachtgever verklaart hierbij dat deze maatregelen een passend
            beschermingsniveau waarborgen, rekening houdend met de stand van de techniek en de kosten van implementatie
            en gelet op de risico's die de verwerking en de aard van de te beschermen gegevens met zich meebrengen.
          </li>
          <li>
            Indien een beveiligingsincident plaats vindt ondankt de overeengekomen passende maaregelen die
            dienstverlener heeft geimplementeerd, met betrekking tot i) vernietiging, ii) verlies, iii) vervalsing, iv)
            ongeoorloofde verspreiding van en / of toegang tot, of v) elke andere vorm van onrechtmatige verwerking van
            persoonsgegevens, kan de opdrachtgever dienstverlener niet aansprakelijk stellen voor enige schade die
            daardoor ontstaat.
          </li>
          <li>
            Indien opdrachtgever expliciet maatregelen vraagt die naar het oordeel van dienstverlener niet als passende
            technische en organisatorische maatregelen kunnen worden aangemerkt, zal de uitvoering van deze maatregelen
            geheel voor rekening en risico van opdrachtgever zijn en aanvaardt dienstverleer geen aansprakelijkheid voor
            schade die door de opdrachtgever of door derden is geleden.
          </li>
          <li>
            De opdrachtgever vrijwaart dienstverlener volledig tegen alle aanspraken van derden, waaronder, maar niet
            beperkt tot, sancties van toezichthouders die op enige wijze zijn gebaseerd op de stelling dat de door
            dienstverlener getroffen technische en organisatorische maatregelen als bedoeld in artikel 10.8 niet
            geschikt en / of anderszins niet adequaat zijn.
          </li>
        </ol>
        <h3 id="artikel-25-geheimhouding">Artikel 25 – Geheimhouding</h3>
        <ol>
          <li>
            Ieder der partijen houdt de informatie die hij (in welke vorm dan ook) van de andere partij ontvangt en alle
            andere informatie betreffende de andere partij waarvan hij weet of redelijkerwijs kan vermoeden dat deze
            geheim of vertrouwelijk is, dan wel informatie waarvan hij kan verwachten dat de verspreiding daarvan de
            andere partij schade kan berokkenen, geheim en neemt alle nodige maatregelen om te waarborgen dat zijn
            personeel de genoemde informatie ook geheimhoudt.
          </li>
          <li>De in het eerste lid van dit artikel genoemde geheimhoudingsplicht geldt niet voor informatie:</li>
        </ol>
        <ol>
          <li>
            die op het moment dat de ontvanger deze informatie ontving al openbaar was of nadien openbaar is geworden
            zonder een schending door de ontvangende partij van een op hem rustende geheimhoudingsplicht;
          </li>
          <li>
            waarvan de ontvangende partij kan bewijzen dat deze informatie al in zijn bezit was op het moment van
            verstrekken door de andere partij;
          </li>
          <li>
            die de ontvangende partij van een derde heeft ontvangen waarbij deze derde gerechtigd was deze informatie
            aan de ontvangende partij te verstrekken
          </li>
          <li>die door de ontvangende partij openbaar wordt gemaakt op grond van een wettelijke plicht.</li>
        </ol>
        <ol>
          <li>
            De in dit artikel omschreven geheimhoudingsplicht geldt voor de duur van deze overeenkomst en voor een
            periode van drie jaar na het eindigen daarvan.
          </li>
        </ol>
        <h3 id="artikel-26-boete-op-overtreding-geheimhoudingsplicht">
          Artikel 26 – Boete op overtreding geheimhoudingsplicht
        </h3>
        <ol>
          <li>
            Overtreedt opdrachtgever het artikel van deze algemene voorwaarden over geheimhouding, dan verbeurt
            opdrachtgever ten behoeve van dienstverlener een onmiddellijk opeisbare boete van €10.000 voor elke
            overtreding en daarnaast een bedrag van €1.000 voor elke dag dat die overtreding voortduurt. Dit is ongeacht
            of de overtreding aan opdrachtgever kan worden toegerekend. Bovendien is er voor het verbeuren van deze
            boete geen voorafgaande ingebrekestelling of gerechtelijke procedure benodigd. Ook hoeft er geen sprake te
            zijn van enige vorm van schade.
          </li>
          <li>
            Het verbeuren van de in het eerste lid van dit artikel bedoelde boete doet geen afbreuk aan de overige
            rechten van dienstverlener waaronder zijn recht om naast de boete schadevergoeding te vorderen.
          </li>
        </ol>
        <h3 id="artikel-27-nietovername-personeel">Artikel 27 – Niet–overname personeel</h3>
        <p>
          Opdrachtgever neemt geen medewerkers van dienstverlener (of van ondernemingen waarop dienstverlener ter
          uitvoering van deze overeenkomst een beroep heeft gedaan en die betrokkenen zijn (geweest) bij de uitvoering
          van de overeenkomst) in dienst. Ook laat hij hen niet anderszins direct of indirect voor zich werken. Dit
          verbod geldt gedurende de looptijd van de overeenkomst tot een jaar na beëindiging daarvan. Op dit verbod
          geldt één uitzondering: partijen kunnen in goed zakelijk overleg met elkaar andere afspraken maken. Deze
          afspraken gelden voor zover die schriftelijk zijn vastgelegd.
        </p>
        <h3 id="artikel-28-toepasselijk-recht-en-bevoegde-rechter">
          Artikel 28 – Toepasselijk recht en bevoegde rechter
        </h3>
        <ol>
          <li>Op iedere overeenkomst tussen partijen is uitsluitend het Nederlands recht van toepassing.</li>
          <li>
            De Nederlandse rechter in het arrondissement waar Firstversionist B.V.is gevestigd/praktijk houdt/kantoor
            houdt is exclusief bevoegd om kennis te nemen van eventuele geschillen tussen partijen, tenzij de wet
            dwingend anders voorschrijft.
          </li>
          <li>
            Partijen verklaren zich in te spannen een geschil in onderling overleg te beslechten voordat zij een beroep
            doen op de rechter.
          </li>
        </ol>
      </Wrap>
      <Footer />
    </>
  );
};
export default NotFoundPage;
